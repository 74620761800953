import React from "react"
import Link from "../components/common/Link"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function tack() {
  return (
    <Layout>
      <Seo title="Tack" />
      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-200">
        <div className="-mt-16 text-center">
          <h1 className="text-3xl md:text-5xl">Tack för ditt meddelande!</h1>
          <Link
            to="/"
            className="underline decoration-primary underline-offset-1"
          >
            Tillbaka till startsidan
          </Link>
        </div>
      </div>
    </Layout>
  )
}
